import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../component/layout';
import Seo from '../component/seo';
import PickupInfo from '../sections/PickupInfo';

export default function Pickup() {
  const {
    strapiMetaPage: { header, description },
  } = useStaticQuery(
    graphql`
      query Pickup {
        strapiMetaPage(page_name: { eq: "pickup" }) {
          header
          description
          page_name
        }
      }
    `,
  );

  return (
    <Layout activePath="/pickup">
      <Seo title={header} description={description} />
      <PickupInfo />
    </Layout>
  );
}
