import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
`;

export const Title = styled.h1`
  color: #000;
  font-size: 34px;
  font-weight: 700;
  margin-bottom: 20px;
  text-transform: uppercase;

  @media (max-width: 960px) {
    font-size: 30px;
  }
`;

export const Image = styled.div`
  max-width: 560px;
  height: auto;
  margin-bottom: 50px;

  @media (max-width: 960px) {
    max-width: 460px;
    height: auto;
  }

  @media (max-width: 578px) {
    max-width: 100%;
    height: auto;
  }
`;

export const Description = styled.div`
  max-width: 760px;
  color: #000;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 1.75;
  margin-bottom: 60px;

  a {
    display: block;
    color: #86b7d1;
    font-weight: 600;
    font-size: 14px;
    margin-top: 30px;
  }

  @media (max-width: 578px) {
    font-size: 14px;
    line-height: 25px;
  }
`;
